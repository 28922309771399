export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前的"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后退"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国人"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马来语"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰米尔语"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缅甸语"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僧伽罗语"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孟加拉"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要退出吗？"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功退出。"])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息发送成功。"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送您的消息时出错。"])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的姓名。"])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的电子邮件。"])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的电子邮件。"])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入消息。"])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不匹配。"])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保选择了地区并且输入了有效的电话号码。"])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在将验证码发送到您的号码，请稍候。"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎！我们很高兴您能来这里"])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录成功，欢迎回来"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保您输入了有效的电子邮件和密码。"])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保您输入了有效的名称。"])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功注册。"])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码重置成功。"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入密码以继续"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个或多个输入字段不符合条件。"])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的邮件已成功发送。"])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功注册此课程。"])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈提交成功。"])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您回答的是第一个问题！"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须填写答案才能继续！。"])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您现在正在问最后一个问题，您确定要提交答案吗？"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验提交成功。"])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性验证码 (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年龄"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团体"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["老年"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移民"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择性别"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语水平"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择熟练程度"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常差"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贫穷的"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均的"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好的"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常好"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有五分钟的空闲时间来做我们的志愿者吗？"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的数字志愿者在世界任何地方帮助我们。我们的系统只会在您有空时通知您，让您利用业余时间为我们的移民家庭辅导或开设新课程。"])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进入"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取 OTP"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线课程"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自由的"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为每个人提供工具和资源"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无需付出任何代价就能增强自己的能力。"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即开始"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持多语言"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["助你成长的课程"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久免费课程"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的作品"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技能"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["训练"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的目标是帮助外籍工人提高他们的认知技能，以便在新加坡承担更重要的角色，并让他们有更好的机会提高和发展自己。"])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线教学"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培养技能"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改善生活"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生长"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们一起创造未来！"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量身定制的课程帮助您提高技能。"])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教员总数"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用课程"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不同的语言"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新课程"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新课程"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服用"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到下一个"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与技术同步"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["助你成长的课程"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费在线课程"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为每个人提供工具和资源，以增强自己的能力"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费。"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始课程"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将帮助您"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技能"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和培训"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的目标是通过提高移民工人的技能和能力来改善他们的生活，从而为他们提供更多机会和改善生活。我们致力于让移民工人能够自信而有能力地驾驭充满可能性的世界。"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教员总数"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用课程"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不同的语言"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的最新课程"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看详情"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多课程"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["助你成长的课程"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们一起成长"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到 Growstudio"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow Studio 由 Grow Education Ltd 建立，并由 Seah Kim Cheok Construction Co. (Pte) Ltd 提供支持，是一个免费的在线平台，致力于支持外籍工人学习和成长。在新加坡管理大学和 Superink Pte Ltd 的联合支持下，我们旨在帮助外籍工人获得英语、医疗保健、金融等方面的基本技能，使他们能够改善自己的职业和生活方式。"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的课程以多种语言提供，并包括评估以确保有效学习。鉴于 COVID-19 疫情仍在持续，Grow Education 支持的 Grow Studio 为外国工人提供了一种安全且方便的方式来获得新技能和知识，而无需承担传统课堂环境中的风险。"])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索课程"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择我们"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本网站提供的所有课程和班级都是免费的。它向所有人开放，尤其是外籍工人。"])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的使命"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费为每个人提供工具和资源，帮助他们增强自身能力。"])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的愿景"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展我们当地的移民社区，提高他们的技能和沟通能力。"])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教员总数"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用课程"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不同的语言"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的团队"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认识我们的团队"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的团队由具有不同背景和技能的专家组成，每个人都能带来独特的观点。"])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常务董事"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT 开发总监"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行政总监"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育总监"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT DevOps 领导者"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["志愿者"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即报名成为一名志愿者，帮助我们的农民工完成教育"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写此表格，我们将尽快回复您。"])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择您擅长教授的语言"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索我们的课程，与我们一起成长"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索课程"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择课程语言"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择课程开始学习"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常规详细信息"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自由的"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看详情"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他视频"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择课程以查看更多详细信息"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前就读"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推介会"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的演示"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载 Powerpoint"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们以获得更多发展"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎通过电子邮件联系我们"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的名字"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接触"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮廓"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人的"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人资料"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团体"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["老年"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移民"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司详细信息"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选修课程"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您尚未注册任何课程"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程进度"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验进度"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评估"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成评估"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您尚未完成任何评估"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地位"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分数"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看答案"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导师工具"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件或电话号码"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有帳戶？"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？ "])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或使用以下方式登录"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心情低落？拨打热线：+65 3129 5000"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取 OTP"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已有账户？"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法注册？"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击这里联系我们"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 登录目前不可用。"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook 登录目前不可用。"])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 SMU 和 SuperINK 支持"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国人"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马来语"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰米尔语"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缅甸语"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僧伽罗语"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孟加拉"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 Seah Kim Cheok Construction Co. (Pte) Ltd 建造"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特点"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接触"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮廓"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录/注册"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["志愿者"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费为每个人提供工具和资源，帮助他们增强自身能力。"])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速连接"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建造者"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在本课程中，您将从学习英语基础知识开始，例如字母、数字和语法。之后，您将学习如何进行简单的对话，这将帮助您与家人和朋友交流。您还将学习如何在更常见的场景中交谈，例如点餐和去看医生。"])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从第一级开始，您将更深入地学习英语语法，例如时态、标点符号和连词。此外，您还将学习更多个人和工作环境中的对话技巧，例如表达您的感受、撰写电子邮件以及在各种工作相关场景中交谈。"])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["达到 3 级后，我们将开始处理更复杂的主题，例如介词、情态动词，然后进一步掌握阅读和理解的基本技能。最后，我们将在期末复习课中总结您所学到的一切，并提出建议，帮助您未来的英语学习。"])}
  }
}