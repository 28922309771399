export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malay"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bengali"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to log out?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged out successfully."])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent successfully."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error sending your message."])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your name."])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email."])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email."])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a message."])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure a region is selected and that you have entered a valid phone number."])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait while we send the verification code to your number."])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome! We're excited to have you here"])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logged in successfully, welcome back"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure you have entered a valid email and password."])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure you have entered a valid name."])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully signed up."])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset successfully."])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password to continue"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more input fields does not fulfill criteria."])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email has been sent successfully."])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully enrolled in this course."])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback submitted successfully."])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are on the first question!"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer must be filled before you can proceed!."])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are on the last question, are you sure you wish to submit your answers?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz submitted successfully."])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singaporean"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Time Verification Code (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elderly"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrant"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Gender"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Proficiency"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Proficiency"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very Poor"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very Good"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have 5 minutes to spare to be our volunteer?"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our digital volunteers help us from anywhere they want in the World. Our system only notifies you when you are free and allow you to spend your spare time in tutoring or creating new classes for our migrant family."])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enroll"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Course"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing everyone and anyone with the tools and resources to"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empower themselves at no cost."])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Language Supported"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses to help you grow"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free courses, forever"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Works"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our aim is to help foreign workers improve on their cognitive skills in order take on stronger roles in Singapore and also give them a better chance to improve and develop themselves."])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Teaching Learning"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developing Skills"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving Lifes"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["future with us!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailored courses to help you improve your skills."])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Instructors"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses Available"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different Languages"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated Courses"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Courses"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the Next"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level with Technology"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses to help you grow"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Online Course"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing everyone and anyone with the tools and resources to empower themselves"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at no cost."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START COURSE"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll help you with"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And Training"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our aim is to uplift the lives of migrant workers by enhancing their skills and competencies, thereby opening doors to increased opportunities and improved livelihoods. We are committed to enable migrant workers to navigate a world of possibilities with confidence and competence."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEARN MORE"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Instructors"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses Available"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different Languages"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Latest Courses"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEE DETAILS"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MORE COURSES"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses to help you grow"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow With Us"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Growstudio"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Built by Grow Education Ltd and supported by Seah Kim Cheok Construction Co. (Pte) Ltd, Grow Studio is a free online platform dedicated to supporting foreign workers to learn and grow. Jointly supported by Singapore Management University and Superink Pte Ltd, we aim to help foreign workers acquire essential skills in English, Healthcare, Finance, and more, empowering them to improve their careers and lifestyles."])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our courses are offered in multiple languages and include assessments to ensure effective learning. In light of the ongoing COVID-19 pandemic, Grow Studio—enabled by Grow Education—provides a safe and accessible way for foreign workers to gain new skills and knowledge, without the risks associated with traditional classroom settings."])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPORE COURSES"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Us"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every lesson and class provided on this site is free of charge. It is open to anyone, especially foreign workers."])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Mission"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide everyone and anyone with the tools and resources to empower themselves at no cost."])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Visions"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To grow our local migrant community, improving their skills and communication."])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Instructors"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses Available"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different Languages"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducing"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet Our Team"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team consists of experts with diverse backgrounds and skill sets, each bringing their unique perspective to the table."])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director of IT Development"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director of Administration"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director of Education"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT DevOps Leader"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up as a volunteer today and help our migrant workers with their education"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill up this form and we will get back to you shortly."])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select languages you are comfortable teaching"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore our classes to grow with us"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Courses"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Course Language"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a course to start learning"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Details"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENROLL"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Details"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other videos"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a lesson to view more details"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Studying"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No presentation available"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Powerpoint"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get In Touch"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us to grow more"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feel free to reach us via email"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Details"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elderly"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrant"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Details"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses Enrolled"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not enrolled in any courses"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Progress"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz Progress"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments Completed"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not completed any assessments"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Answer"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutor Tools"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or Phone Number"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account? "])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password? "])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or sign in with"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feeling Down? Call This Hotline: +65 3129 5000"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account? "])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to sign up?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us here"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Sign In is not available at the moment."])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Sign In is not available at the moment."])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported by SMU and SuperINK"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malay"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bengali"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Built by Seah Kim Cheok Construction Co. (Pte) Ltd"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN/SIGN UP"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGOUT"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing everyone and anyone with the tools and resources to empower themselves at no cost."])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LINKS"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUICK CONNECT"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Built by"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported by"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this course, you will start by learning the basics of English, such as alphabets, numbers and grammar.  Following that, you will learn how to make simple conversations which will help you communicate with your family and friends. You will also learn how to converse in more common scenarios such as ordering food and going to the doctor."])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuing from level 1, you will learn more in-depth about English grammar such as tenses, punctuations and conjunctions. In addition, you will learn more conversational skills in both personal and work settings, such as expressing your feelings, writing emails and conversing in various work related scenarios."])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaching level 3, we move into tackling more complicated topics such as prepositions, modal verbs, then advance into picking up essential skills for reading and comprehension. Finally, we will conclude all you have learnt in a final review session and offer suggestions to help you on your future English studies."])}
  }
}